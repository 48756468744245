import React from "react";
import GMeetLogo from "../../../assets/Image/gmeet_logo.png";
import MsTeamLogo from "../../../assets/Image/msteam_logo.png";
import ZoomLogo from "../../../assets/Image/zoom_logo.png";

const MeetingHeading = ({ meetingId, userEmail, participant, source, datetime }) => {
	console.log("MeetingHeading", meetingId);
	const dateTime = new Date(datetime);

	const formattedDate = new Intl.DateTimeFormat("en-US", {
		weekday: "short",
		month: "short",
		day: "2-digit",
		year: "numeric",
		timeZone: "Asia/Kolkata",
	}).format(dateTime);

	const formattedTime = new Intl.DateTimeFormat("en-US", {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
		timeZone: "Asia/Kolkata", // Ensure consistent timezone
	}).format(dateTime);

	// Conditional rendering of logo based on source
	let logo;
	if (source === "Google Meet") {
		logo = <img src={GMeetLogo} alt="Google Meet Logo" className="h-5 w-5" />;
	} else if (source === "MS Team") {
		logo = <img src={MsTeamLogo} alt="MS Teams Logo" className="h-5 w-5" />;
	} else if (source === "Zoom") {
		logo = <img src={ZoomLogo} alt="Zoom Logo" className="h-5 w-5" />;
	} else {
		logo = <span>No Logo</span>; // Fallback for unknown sources
	}

	return (
		<>
			<div className="mb-4 py-3 pr-4 ">
				{/* First Row */}
				<div className="flex mb-2">
					<div className="px-3">
						<strong>Meeting ID:</strong> {meetingId}
					</div>
					<div className="px-3">
						<strong>Participant:</strong> {participant}
					</div>
				</div>
				{/* Second Row */}
				<div className="flex items-center space-x-1">
					<div className="pl-2 flex">
						<div className="px-1">{formattedDate}</div>
						<div className="px-1 text-gray-400">|</div>
						<div className="px-1">{formattedTime}</div>
						<div className="px-1 text-gray-400">|</div>
					</div>
					<div className="flex items-center space-x-2">{logo}</div>
				</div>
			</div>
		</>
	);
};

export default MeetingHeading;
