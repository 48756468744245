import React from "react";
// import '../css/loader.css';
import Loading from "../assets/Image/Loading.gif";

const Loader1 = () => {
	return (
		<div className="flex items-center justify-center">
			<div
				className="bg-no-repeat bg-center bg-cover w-36 h-36 rounded"
				style={{ backgroundImage: `url(${Loading})` }}
			></div>
		</div>
	);
};

export default Loader1;
