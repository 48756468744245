import "./App.css";
import AuthPage from "./component/AuthPage";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import Home from "./component/Home";
import UserDummyComponent from "./component/Dummy";
import MeetingDetails from "./component/MeetingDetails/MeetingDetails";
import BotDetails from "./component/BotDetail";
import IntegrationsOutlook from "./component/calendaroutlook";
import Integrations from "./component/Integrations";
import Sidebar from "./component/Sidebar"; // Import Sidebar

function App() {
	const location = useLocation(); // Get the current location
	// Check if the current path is the AuthPage
	const showSidebar = location.pathname !== "/" && location.pathname !== "/admin";

	return (
		<div className="app">
			{showSidebar && <Sidebar />}
			<div className={`main-content ${showSidebar ? "with-sidebar" : "without-sidebar"}`}>
				<Routes>
					<Route path="/" element={<AuthPage />} />
					<Route path="/login/success" element={<UserDummyComponent />} />
					<Route path="/notetaker" element={<Home />} />
					<Route path="/meetingdetails" element={<MeetingDetails />} />
					<Route path="/admin" element={<BotDetails />} />
					<Route path="/notetaker/user/integrations" element={<Integrations />} />
					<Route
						path="/notetaker/integrations/user/calendar/outlook"
						element={<IntegrationsOutlook />}
					/>
				</Routes>
			</div>
		</div>
	);
}

// Wrap the App component with Router
const WrappedApp = () => (
	<Router>
		<App />
	</Router>
);

export default WrappedApp;
