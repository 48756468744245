// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// // import { setTitle, setMeetingUrl, setLoading, resetForm } from '../redux/slices/liveMeetingSlice';
// import { setTitle, setMeetingUrl, setLoading, resetForm } from '../../redux/slices/liveMeetingSlice';


// const LiveMeetingForm = ({ onSubmit }) => {
//   const dispatch = useDispatch();
//   const { title, meetingUrl, isLoading } = useSelector((state) => state.liveMeeting);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     dispatch(setLoading(true));
//     await onSubmit({ title, meetingUrl });
//     dispatch(setLoading(false));
//     dispatch(resetForm()); // Reset form after submission
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">Name of Meeting</label>
//         <input
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
//           type="text"
//           value={title}
//           onChange={(e) => dispatch(setTitle(e.target.value))}
//           required
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">Meeting URL</label>
//         <input
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
//           type="text"
//           value={meetingUrl}
//           onChange={(e) => dispatch(setMeetingUrl(e.target.value))}
//           required
//         />
//       </div>
//       <button
//         className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
//         type="submit"
//         disabled={isLoading}
//       >
//         {isLoading ? 'Adding...' : 'Add to Live Meeting'}
//       </button>
//     </form>
//   );
// };

// export default LiveMeetingForm;


// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setTitle, setMeetingUrl, setLoading, resetForm } from '../../redux/slices/liveMeetingSlice';

// const LiveMeetingForm = ({ onSubmit, onClose }) => { // Accept onClose prop
//   const dispatch = useDispatch();
//   const { title, meetingUrl, isLoading } = useSelector((state) => state.liveMeeting);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     dispatch(setLoading(true));
//     await onSubmit({ title, meetingUrl });
//     dispatch(setLoading(false));
//     dispatch(resetForm()); // Reset form after submission
//     onClose(); // Close the modal after submission
//   };

//   return (
//     <form onSubmit={handleSubmit} className="p-4 bg-white rounded-lg shadow-md">
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">Name of Meeting</label>
//         <input
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
//           type="text"
//           value={title}
//           onChange={(e) => dispatch(setTitle(e.target.value))}
//           required
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block text-gray-700 text-sm font-bold mb-2">Meeting URL</label>
//         <input
//           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
//           type="text"
//           value={meetingUrl}
//           onChange={(e) => dispatch(setMeetingUrl(e.target.value))}
//           required
//         />
//       </div>
//       <div className="flex justify-between">
//         <button
//           className="bg-red-500 text-white font-bold py-2 px-4 rounded"
//           type="button"
//           onClick={onClose} // Call the onClose function when clicked
//         >
//           Cancel
//         </button>
//         <button
//           className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
//           type="submit"
//           disabled={isLoading}
//         >
//           {isLoading ? 'Adding...' : 'Add to Live Meeting'}
//         </button>
//       </div>
//     </form>
//   );
// };

// export default LiveMeetingForm;




import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle, setMeetingUrl, setLoading, resetForm, addToLiveMeeting } from '../../redux/slices/liveMeetingSlice';
import Loader from '../Loader';
import Loader1 from '../Loader1';

const LiveMeetingForm = ({ onClose }) => { 
  const dispatch = useDispatch();
  const { title1, meetingUrl, isLoading, error } = useSelector((state) => state.liveMeeting);
  const userEmail = useSelector((state) => state.user.userEmail);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    const meetingData = { title1, meetingUrl ,userEmail};
    await dispatch(addToLiveMeeting(meetingData)); 
    dispatch(resetForm()); 
    onClose(); 
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>
                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                      <div className="inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle sm:max-w-3xl sm:w-full">
                        {isLoading ? <Loader1 /> : (
                          <form onSubmit={handleSubmit} className="p-4 bg-white rounded-lg shadow-md">
                            {error && <p className="text-red-500">{error}</p>} 
                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2">Name of Meeting</label>
                              <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                                type="text"
                                value={title1}
                                onChange={(e) => dispatch(setTitle(e.target.value))}
                                required
                              />
                            </div>
                            <div className="mb-4">
                              <label className="block text-gray-700 text-sm font-bold mb-2">Meeting URL</label>
                              <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                                type="text"
                                value={meetingUrl}
                                onChange={(e) => dispatch(setMeetingUrl(e.target.value))}
                                required
                              />
                            </div>
                            <div className="flex justify-end">
                              <button
                                className="bg-red-500 text-white font-bold py-2 px-4 mx-4 rounded"
                                type="button"
                                onClick={onClose}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                                type="submit"
                                disabled={isLoading}
                              >
                                {isLoading ? 'Adding...' : 'Add to Live Meeting'}
                              </button>
                            </div>
                          </form>
                       )} 
                      </div>
      </div>
      </div>
  )
};

export default LiveMeetingForm;
