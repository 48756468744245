import React from "react";
import { isRouteErrorResponse } from "react-router-dom";

const MeetingSummary = ({ meetSummary }) => {
	console.log("MeetSummary", meetSummary);
	const meetingStartTime = meetSummary?.meetingStartTime;
	const dateObject = new Date(meetingStartTime);
	const formattedDate = dateObject.toLocaleDateString("en-US", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	});
	const formattedTime = dateObject.toLocaleTimeString("en-US", {
		timeZone: "Asia/Kolkata",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
	});
	const processText = (text) => {
		if (!text) return [];
		return text
			.split("\n") // Split into lines
			.map((line) => line.replace(/^[\d\.\-\s]+/, "").trim())
			.filter((line) => line); // Filter out empty lines
	};

	const parseActionItems = (actionItemsText) => {
		const lines = processText(actionItemsText);
		return lines.slice(2).map((line) => {
			const cleanedLine = line.replace(/^\|+|\|+$/g, "").trim();
			const [actionItem, assignedTo, deadline] = cleanedLine.split("|").map((item) => item.trim());

			return {
				actionItem: actionItem || "N/A",
				assignedTo: assignedTo || "N/A",
				deadline: deadline || "Immediate",
			};
		});
	};

	const attendees = meetSummary?.orderedSpeaker || [];
	const attendeesList = attendees.length > 0 ? attendees.join(", ") : "The meeting was silent.";
	console.log("attendeesList", attendeesList);

	let meetingSummary;
	let keyPoints;
	let decisionsMade;
	let actionItems;

	// Debug the message value
	console.log("meetSummary.meetsummary.message:", meetSummary?.meetsummary?.message);

	if (meetSummary?.meetsummary?.message === "Meeting transcription is too small") {
		console.log("Meeting transcription is too small");
		meetingSummary = "The meeting was too short to compile a detailed summary.";
		keyPoints = "The meeting duration was insufficient to highlight key points discussed.";
		decisionsMade = "The meeting duration was insufficient to decide any decisions made.";
		actionItems = "The meeting duration was insufficient to generate actionable items.";
	} else {
		console.log("Processing regular meeting data");
		meetingSummary = processText(meetSummary?.meetsummary?.meeting_summary || "");
		keyPoints = meetSummary?.meetsummary?.key_points_discussed || [];
		decisionsMade = meetSummary?.meetsummary?.decisions_made || [];
		actionItems = parseActionItems(meetSummary?.meetsummary?.action_items || "");
	}

	// Log final values for debugging
	console.log("meetingSummary:", meetingSummary);
	console.log("keyPoints:", keyPoints);
	console.log("decisionsMade:", decisionsMade);
	console.log("actionItems:", actionItems);

	// let meetingSummary;
	// let keyPoints;
	// let decisionsMade;
	// let actionItems;

	// if (meetSummary?.meetsummary?.message === "Meeting transcription is too small") {
	//   console.log("Meeting smlaasds");

	// 	meetingSummary = "Meeting is too short to provide a summary";
	// 	keyPoints = "No key points were discussed in the meeting.";
	// 	decisionsMade = "No decisions were made during the meeting.";
	// 	actionItems = "No action items were recorded for the meeting.";
	// } else {
	// 	meetingSummary = processText(meetSummary?.meetsummary?.meeting_summary);
	// 	keyPoints = meetSummary?.meetsummary?.key_points_discussed;
	// 	decisionsMade = meetSummary?.meetsummary?.decisions_made;
	// 	actionItems = parseActionItems(meetSummary?.meetsummary?.action_items);
	// }

	// const meetingSummary = meetSummary?.meetsummary?.meeting_summary
	// 	? processText(meetSummary.meetsummary.meeting_summary)
	// 	: "Meeting is too short to provide a summary";

	// const keyPoints = meetSummary?.meetsummary?.key_points_discussed
	// 	? meetSummary.meetsummary.key_points_discussed
	// 	: "No key points were discussed in the meeting.";

	// const decisionsMade = meetSummary?.meetsummary?.decisions_made
	// 	? meetSummary.meetsummary.decisions_made
	// 	: "No decisions were made during the meeting.";

	//   const keyPoints = processText(meetSummary?.meetsummary?.key_points_discussed);
	//   const decisionsMade = processText(meetSummary?.meetsummary?.decisions_made);

	// const actionItems = meetSummary?.meetsummary?.action_items
	// 	? parseActionItems(meetSummary.meetsummary.action_items)
	// 	: "No action items were recorded for the meeting.";

	return (
		<div className="max-h-[100vh] overflow-y-auto">
			<div className="bg-gray-100 p-4">
				<div className="max-w-7xl mx-auto bg-white shadow-md rounded-md p-6">
					{/* Header */}
					<header className="border-b pb-4 mb-6">
						<h1 className="text-2xl font-bold text-gray-800">Meeting Summary and Action Plan</h1>
					</header>

					{/* Two-Column Layout */}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						{/* Left Column */}
						<div>
							<p className="text-gray-700 mb-1">
								<strong>Date:</strong> {formattedDate}
							</p>
							<p className="text-gray-700 mb-1">
								<strong>Time:</strong> {formattedTime}
							</p>
							<p className="text-gray-700 mb-1">
								<strong>Attendees:</strong> {attendeesList}
							</p>

							<h3 className="text-lg font-semibold text-gray-800 mt-6 mb-3">Summary</h3>

							<p className="text-gray-700 text-md mb-2">{meetingSummary}</p>

							<h3 className="text-lg font-semibold text-gray-800 mt-6 mb-3">
								Key Points Discussed
							</h3>
							<ul className="list-disc list-inside text-gray-700 text-md space-y-1">
								{Array.isArray(keyPoints) ? (
									keyPoints.map((point, index) => <li key={index}>{point}</li>)
								) : (
									<p className="text-gray-700 text-md">{keyPoints}</p>
								)}
							</ul>

							<h3 className="text-lg font-semibold text-gray-800 mt-6 mb-3">Decisions Made</h3>
							<ul className="list-disc list-inside text-gray-700 text-md space-y-1">
								{Array.isArray(decisionsMade) ? (
									decisionsMade.map((point, index) => <li key={index}>{point}</li>)
								) : (
									<p className="text-gray-700 text-md">{decisionsMade}</p> // If it's a string, display it as a single item
								)}
							</ul>
						</div>

						{/* Right Column */}
						<div>
							<h2 className="text-xl font-semibold text-gray-800 mb-3">Action Items</h2>
							{Array.isArray(actionItems) && actionItems.length > 0 ? (
								<table className="min-w-full table-auto border-collapse border border-gray-300 text-gray-700 text-md">
									<thead className="bg-gray-200">
										<tr>
											<th className="border border-gray-300 px-4 py-2 text-left">
												Action Item
											</th>
											<th className="border border-gray-300 px-4 py-2 text-left">
												Assigned To
											</th>
											<th className="border border-gray-300 px-4 py-2 text-left">
												Deadline
											</th>
										</tr>
									</thead>
									<tbody>
										{actionItems.map((action, index) => (
											<tr key={index}>
												<td className="border border-gray-300 px-4 py-2">
													{action.actionItem || "N/A"}
												</td>
												<td className="border border-gray-300 px-4 py-2">
													{action.assignedTo || "N/A"}
												</td>
												<td className="border border-gray-300 px-4 py-2">
													{action.deadline || "Immediate"}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								<p className="text-gray-700 text-md">{actionItems}</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MeetingSummary;
