// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./slices/sidebarSlice"; // Your sidebar slice reducer
import liveMeetingReducer from "./slices/liveMeetingSlice";
import scheduleMeetingReducer from "./slices/scheduleMeetingSlice";
import userReducer from "./slices/userSlice";
export const store = configureStore({
	reducer: {
		sidebar: sidebarReducer,
		liveMeeting: liveMeetingReducer,
		scheduleMeeting: scheduleMeetingReducer,
		user: userReducer,
	},
});
