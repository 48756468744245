// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import Sidebar from './Sidebar';
// import Modal from './Modal';
// import { toggleScheduleMeetingModal, toggleLiveMeetingModal } from '../redux/slices/sidebarSlice'; // Use toggle actions
// import LiveMeetingForm from './Modals/ LiveMeetingForm.js';
// const Home = () => {
//   const dispatch = useDispatch();
//   const isScheduleMeetingModalOpen = useSelector((state) => state.sidebar.isScheduleMeetingModalOpen);
//   const isLiveMeetingModalOpen = useSelector((state) => state.sidebar.isLiveMeetingModalOpen);

//   const handleCloseScheduleMeeting = () => {
//     dispatch(toggleScheduleMeetingModal()); // Toggle instead of close
//   };

//   const handleCloseLiveMeeting = () => {
//     dispatch(toggleLiveMeetingModal()); // Toggle instead of close
//   };

//   return (
//     <div>
//       {isScheduleMeetingModalOpen && (
//         <Modal onClose={handleCloseScheduleMeeting}>
//           <div>Schedule Meeting Form</div>
//         </Modal>
//       )}
//       {isLiveMeetingModalOpen && (
//         <Modal onClose={handleCloseLiveMeeting}>
//           <div>Live Meeting Form</div>
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Home;

// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import Modal from './Modal';
// import { toggleScheduleMeetingModal, closeScheduleMeetingModal, toggleLiveMeetingModal } from '../redux/slices/sidebarSlice';
// import LiveMeetingForm from './Modals/LiveMeetingForm';
// import ScheduleMeetingForm from './Modals/schedulemodal';

// const Home = () => {
//   const dispatch = useDispatch();
//   const isScheduleMeetingModalOpen = useSelector((state) => state.sidebar.isScheduleMeetingModalOpen);
//   const isLiveMeetingModalOpen = useSelector((state) => state.sidebar.isLiveMeetingModalOpen);

//   const handleCloseScheduleMeeting = () => {
//     dispatch(closeScheduleMeetingModal()); // Use close action instead of toggle
//   };

//   const handleCloseLiveMeeting = () => {
//     dispatch(toggleLiveMeetingModal());
//   };

//   const handleLiveMeetingSubmit = async (data) => {
//     console.log("Live Meeting Data:", data);
//     handleCloseLiveMeeting();
//   };

//   return (
//     <div>
//       {isScheduleMeetingModalOpen && (
//         <Modal onClose={handleCloseScheduleMeeting}>
//          <ScheduleMeetingForm onSubmit={handleLiveMeetingSubmit} />
//         </Modal>
//       )}
//       {isLiveMeetingModalOpen && (
//         <Modal onClose={handleCloseLiveMeeting}>
//           <LiveMeetingForm onSubmit={handleLiveMeetingSubmit} />
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Home;

// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import Modal from './Modal';
// import { toggleScheduleMeetingModal, closeScheduleMeetingModal, toggleLiveMeetingModal } from '../redux/slices/sidebarSlice';
// import LiveMeetingForm from './Modals/LiveMeetingForm';
// import ScheduleMeetingForm from './Modals/schedulemodal'; // Adjust import according to your structure

// const Home = () => {
//   const dispatch = useDispatch();
//   const isScheduleMeetingModalOpen = useSelector((state) => state.sidebar.isScheduleMeetingModalOpen);
//   const isLiveMeetingModalOpen = useSelector((state) => state.sidebar.isLiveMeetingModalOpen);

//   const handleCloseScheduleMeeting = () => {
//     dispatch(closeScheduleMeetingModal());
//   };

//   const handleCloseLiveMeeting = () => {
//     dispatch(toggleLiveMeetingModal());
//   };

//   const handleLiveMeetingSubmit = async (data) => {
//     console.log("Live Meeting Data:", data);
//     handleCloseLiveMeeting();
//   };

//   const handleScheduleMeetingSubmit = async (data) => {
//     console.log("Schedule Meeting Data:", data);
//     handleCloseScheduleMeeting(); // Close the modal after submission
//   };

//   return (
//     <div>
//       {isScheduleMeetingModalOpen && (
//         <Modal onClose={handleCloseScheduleMeeting}>
//           <ScheduleMeetingForm onSubmit={handleScheduleMeetingSubmit} onClose={handleCloseScheduleMeeting} />
//         </Modal>
//       )}
//       {isLiveMeetingModalOpen && (
//         <Modal onClose={handleCloseLiveMeeting}>
//           <LiveMeetingForm onSubmit={handleLiveMeetingSubmit} onClose={handleCloseLiveMeeting} />
//         </Modal>
//       )}
//     </div>
//   );
// };

// export default Home;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "./Modal";
import {
	toggleScheduleMeetingModal,
	closeLiveMeetingModal,
	closeScheduleMeetingModal,
	LiveMeetings,
	setMeetings,
	ScheduledMeeting,
	toggleLiveMeetingModal,
	UpcomingMeeting,
	IncompleteMeeting,
} from "../redux/slices/sidebarSlice";
import LiveMeetingForm from "./Modals/LiveMeetingForm";
import ScheduleMeetingForm from "./Modals/schedulemodal";
import { addToLiveMeeting } from "../redux/slices/liveMeetingSlice";
import Navbar from "./Navbar";
import axios from "axios";
import { FaClock } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MeetingDetails from "./MeetingDetails/MeetingDetails";
import Sidebar from "./Sidebar";
import MeetingTable from "./MeetingTable";
import { format, isAfter, isBefore } from "date-fns";

const Home = () => {
	const dispatch = useDispatch();
	const isScheduleMeetingModalOpen = useSelector((state) => state.sidebar.isScheduleMeetingModalOpen);
	const isLiveMeetingModalOpen = useSelector((state) => state.sidebar.isLiveMeetingModalOpen);
	const Meeting = useSelector((state) => state.sidebar.Meetings || []);
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [isCompleted, setisCompleted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedMeetingType, setSelectedMeetingType] = useState("Live");
	const meetingsPerPage = 8;
	console.log("Meetinghsdsd", Meeting);

	const navigate = useNavigate();

	const fetchMeetingsByType = async (type) => {
		const user = JSON.parse(sessionStorage.getItem("user"));
		let userEmail = user.email;

		if (type === "Live") {
			dispatch(LiveMeetings(userEmail)); // Fetch live meetings
		} else if (type === "Scheduled") {
			// Replace with API to fetch scheduled meetings
			dispatch(ScheduledMeeting(userEmail)); // You need to dispatch the fetched data
		} else if (type === "Upcoming") {
			// Replace with API to fetch upcoming meetings
			dispatch(UpcomingMeeting(userEmail));
		} else if (type === "Incomplete") {
			// Replace with API to fetch incomplete meetings
			dispatch(IncompleteMeeting(userEmail));
		}
	};

	useEffect(() => {
		fetchMeetingsByType(selectedMeetingType); // Fetch meetings based on the selected type
	}, [selectedMeetingType]);

	useEffect(() => {
		if (Meeting.length > 0) {
			setTotalPages(Math.ceil(Meeting.length / meetingsPerPage));
		}
	}, [Meeting]);

	const handleCloseScheduleMeeting = () => {
		dispatch(closeScheduleMeetingModal());
	};

	const handleCloseLiveMeeting = () => {
		dispatch(closeLiveMeetingModal());
	};

	const handleLiveMeetingSubmit = async (data) => {
		console.log("Live Meeting Data:", data);
		dispatch(addToLiveMeeting(data));
		handleCloseLiveMeeting(); // Close modal after submitting
	};

	const handlePageChange = (direction) => {
		if (direction === "prev" && currentPage > 1) {
			setCurrentPage(currentPage - 1);
		} else if (direction === "next" && currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const handleMeetingLinkClick = (meetUrl) => {
		startRecording(meetUrl);
	};

	const startRecording = async (meetUrl) => {
		try {
			const user = JSON.parse(sessionStorage.getItem("user"));
			let userEmail = user.email;
			console.log("Recording", user);
			setIsLoading(true);
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/start-recording`, {
				meetUrl,
				userEmail,
			});
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleMeetingDetails = async (eventUrl, status) => {
		const parts = eventUrl.split("/");
		const meetingId = parts[parts.length - 1];
		try {
			const user = JSON.parse(sessionStorage.getItem("user"));
			let userEmail = user.email;
			console.log("Meeting Details", user);
			console.log(status);
			// const response = await axios.post('http:///user/meetingdetails', { meetingId,userEmail });
			if (status === "completed") {
				<MeetingDetails />;
				navigate("/meetingdetails", { state: { meetingId, userEmail } });
			} else {
				setisCompleted(true);
			}
		} catch (error) {
			console.error("Error fetching meeting details:", error);
		}
	};

	const handleScheduleMeetingSubmit = async (data) => {
		console.log("Schedule Meeting Data:", data);
		handleCloseScheduleMeeting(); // Close modal after submitting
	};

	const paginatedMeetings = Meeting.slice(
		(currentPage - 1) * meetingsPerPage,
		currentPage * meetingsPerPage,
	);

	return (
		<div className="h-screen flex">
			<div className="flex-1 p-2">
				{isScheduleMeetingModalOpen && (
					<ScheduleMeetingForm
						onSubmit={handleScheduleMeetingSubmit}
						onClose={handleCloseScheduleMeeting}
					/>
				)}
				{isLiveMeetingModalOpen && (
					<LiveMeetingForm onSubmit={handleLiveMeetingSubmit} onClose={handleCloseLiveMeeting} />
				)}
				<Navbar />
				<div>
					<MeetingTable
						meetings={paginatedMeetings}
						handleMeetingDetails={handleMeetingDetails}
						handleMeetingLinkClick={handleMeetingLinkClick}
						currentPage={currentPage}
						totalPages={totalPages}
						handlePageChange={handlePageChange}
						isLoading1={isLoading}
						selectedMeetingType={selectedMeetingType}
						setSelectedMeetingType={setSelectedMeetingType}
					/>
				</div>
			</div>
		</div>
	);
};

export default Home;
