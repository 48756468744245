import React, { useEffect, useState } from "react";
import axios from "axios";

const BotDetails = () => {
	const [botDetails, setBotDetails] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");

	useEffect(() => {
		// Fetch bot details from the backend
		axios
			.get(`${process.env.REACT_APP_API_URL}/api/notetaker-bot/adminlist`)
			.then((response) => {
				setBotDetails(response.data);
				console.log("Response", response);
				setLoading(false);
			})
			.catch((err) => {
				setError("Failed to fetch bot details");
				setLoading(false);
			});
	}, []);

	const handleEndTask = (taskArn) => {
		console.log("TaskARn", taskArn);

		axios
			.post(`${process.env.REACT_APP_API_URL}/api/notetaker-bot/endTask`, { taskArn })
			.then((response) => {
				alert("Are you sure, you want to end task ?");
			})
			.catch((err) => {
				console.error("Error ending task:", err);
				alert("Failed to end task");
			});
	};

	if (loading) {
		return <p className="text-center text-gray-500">Loading...</p>;
	}

	if (error) {
		return <p className="text-center text-red-500">{error}</p>;
	}

	return (
		<div className="container mx-auto p-6">
			<h1 className="text-2xl font-bold mb-6 text-center">Bot Details</h1>
			<div className="overflow-x-auto">
				<table className="min-w-full bg-white border border-gray-200">
					<thead>
						<tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
							<th className="py-3 px-6 text-left">Bot ID</th>
							{/* <th className="py-3 px-6 text-left">Task ARN</th> */}
							<th className="py-3 px-6 text-left">User Email</th>
							<th className="py-3 px-6 text-left">Meeting ID</th>
							<th className="py-3 px-6 text-left">Bot Status</th>
							<th className="py-3 px-6 text-left">Bot Created At</th>
							<th className="py-3 px-6 text-left">Bot End At</th>
							<th className="py-3 px-6 text-left">STOP Task</th>
						</tr>
					</thead>
					<tbody className="text-gray-600 text-sm font-light">
						{botDetails.map((bot) => (
							<tr key={bot._id} className="border-b border-gray-200 hover:bg-gray-100">
								<td className="py-3 px-6 text-left whitespace-nowrap">{bot.botId}</td>
								{/* <td className="py-3 px-6 text-left">{bot.taskArn}</td> */}
								<td className="py-3 px-6 text-left">{bot.userEmail}</td>
								<td className="py-3 px-6 text-left">{bot.meetingId}</td>
								<td className="py-3 px-6 text-left">{bot.botStatus}</td>
								<td className="py-3 px-6 text-left">
									{new Date(bot.botCreatedAt).toLocaleString()}
								</td>
								<td className="py-3 px-6 text-left">
									{bot.botEndAt ? new Date(parseInt(bot.botEndAt)).toLocaleString() : "N/A"}
								</td>
								<td className="py-3 px-6 text-left">
									<button
										className={`py-2 px-4 rounded ${
											bot.botStatus === "RUNNING" && bot.botEndAt === null
												? "bg-blue-500 text-white hover:bg-blue-600"
												: "bg-gray-400 text-gray-700 cursor-not-allowed"
										}`}
										disabled={!(bot.botStatus === "RUNNING" && bot.botEndAt === null)}
										onClick={() => handleEndTask(bot.taskArn)}
									>
										EndTask
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default BotDetails;
