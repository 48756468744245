

// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setLoading, resetForm, updateFormData } from '../../redux/slices/scheduleMeetingSlice';
// import DatePicker from 'react-datepicker';
// import { FaPlus, FaTrash } from 'react-icons/fa';
// import 'react-datepicker/dist/react-datepicker.css';

// const ScheduleMeetingForm = ({ onSubmit, onClose }) => { // Accept onClose prop
//   const dispatch = useDispatch();
//   const formData = useSelector((state) => state.scheduleMeeting.formData);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     dispatch(updateFormData({ ...formData, [name]: value }));
//   };

//   const handleAttendeeChange = (index, e) => {
//     const newAttendees = [...formData.attendees];
//     newAttendees[index] = e.target.value;
//     dispatch(updateFormData({ ...formData, attendees: newAttendees }));
//   };

//   const addAttendee = () => {
//     dispatch(updateFormData({ ...formData, attendees: [...formData.attendees, ''] }));
//   };

//   const removeAttendee = (index) => {
//     const newAttendees = formData.attendees.filter((_, i) => i !== index);
//     dispatch(updateFormData({ ...formData, attendees: newAttendees }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     dispatch(setLoading(true));
//     await onSubmit(formData);
//     dispatch(setLoading(false));
//     dispatch(resetForm());
//     onClose(); // Close the modal after submission
//   };

//   return (
//     <div className="fixed z-10 inset-0 overflow-y-auto">
//       <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//         <div className="fixed inset-0 transition-opacity" aria-hidden="true">
//           <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//         </div>
//         <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
//         <div className="inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle sm:max-w-3xl sm:w-full">
//           <form onSubmit={handleSubmit}>
//             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//               <div className="mb-4">
//                 <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="summary">
//                   Summary
//                 </label>
//                 <input
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   id="summary"
//                   type="text"
//                   placeholder="Summary"
//                   name="summary"
//                   value={formData.summary}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="mb-4">
//                 <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
//                   Description
//                 </label>
//                 <textarea
//                   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                   id="description"
//                   placeholder="Description"
//                   name="description"
//                   value={formData.description}
//                   onChange={handleInputChange}
//                   required
//                 />
//               </div>
//               <div className="flex flex-wrap -mx-3 mb-6">
//                 <div className="w-full md:w-1/2 px-3 py-2">
//                   <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startTime">
//                     Start Time
//                   </label>
//                   <DatePicker
//                     selected={formData.startTime}
//                     onChange={(date) => dispatch(updateFormData({ ...formData, startTime: date }))}
//                     showTimeSelect
//                     timeFormat="HH:mm"
//                     timeIntervals={15}
//                     timeCaption="Time"
//                     dateFormat="MMMM d, yyyy h:mm aa"
//                     className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                     id="startTime"
//                   />
//                 </div>
//                 <div className="w-full md:w-1/2 px-3 py-2">
//                   <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endTime">
//                     End Time
//                   </label>
//                   <DatePicker
//                     selected={formData.endTime}
//                     onChange={(date) => dispatch(updateFormData({ ...formData, endTime: date }))}
//                     showTimeSelect
//                     timeFormat="HH:mm"
//                     timeIntervals={15}
//                     timeCaption="Time"
//                     dateFormat="MMMM d, yyyy h:mm aa"
//                     className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                     id="endTime"
//                   />
//                 </div>
//               </div>
//               <div className="mb-4">
//                 <label className="block text-gray-700 text-sm font-bold mb-2">
//                   Attendees
//                 </label>
//                 {formData.attendees.map((attendee, index) => (
//                   <div key={index} className="flex mb-2 items-center">
//                     <input
//                       type="email"
//                       className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                       placeholder="Attendee Email"
//                       value={attendee}
//                       onChange={(e) => handleAttendeeChange(index, e)}
//                     />
//                     <button
//                       type="button"
//                       className="ml-2 p-2 bg-red-500 text-white rounded-full"
//                       onClick={() => removeAttendee(index)}
//                     >
//                       <FaTrash />
//                     </button>
//                   </div>
//                 ))}
//                 <button
//                   type="button"
//                   className="mt-2 p-2 bg-green-500 text-white rounded-full"
//                   onClick={addAttendee}
//                 >
//                   <FaPlus /> Add Attendee
//                 </button>
//               </div>
//             </div>
//             <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
//               <button
//                 onClick={onClose}
//                 type="button"
//                 className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//               >
//                 Cancel
//               </button>
//               <button
//                 type="submit"
//                 className="ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
//               >
//                 Schedule Meeting
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ScheduleMeetingForm;




// ScheduleMeetingForm.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, resetForm, updateFormData, submitMeeting } from '../../redux/slices/scheduleMeetingSlice';
import DatePicker from 'react-datepicker';
import { FaPlus, FaTrash } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';



const ScheduleMeetingForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.scheduleMeeting.formData);
  const userEmail = useSelector((state) => state.user.userEmail);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateFormData({ ...formData, [name]: value }));
  };

  const handleAttendeeChange = (index, e) => {
    const newAttendees = [...formData.attendees];
    newAttendees[index] = e.target.value;
    dispatch(updateFormData({ ...formData, attendees: newAttendees }));
  };

  const addAttendee = () => {
    dispatch(updateFormData({ ...formData, attendees: [...formData.attendees, ''] }));
  };

  const removeAttendee = (index) => {
    const newAttendees = formData.attendees.filter((_, i) => i !== index);
    dispatch(updateFormData({ ...formData, attendees: newAttendees }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    await dispatch(submitMeeting(formData, userEmail));  // Dispatch the submitMeeting action
    dispatch(resetForm());
    onClose(); // Close the modal after submission
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-6 sm:align-middle sm:max-w-3xl sm:w-full">
          <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
           
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="calendars">Choose a calendar:</label>
              <select
                  id="source"
                  className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 transition duration-200 ease-in-out mb-4"
                  value={formData.source} // Bind the value to formData.source
                  onChange={(e) => dispatch(updateFormData({ ...formData, source: e.target.value }))} // Update on change
                  required
                >
                  <option value="" disabled hidden>Choose an option</option>
                  <option value="Google">Google</option>
                  <option value="Microsoft Teams">Microsoft Teams</option>
                  <option value="Zoom">Zoom</option>
                </select>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="summary">
                  Summary
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="summary"
                  type="text"
                  placeholder="Summary"
                  name="summary"
                  value={formData.summary}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                  Description
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="description"
                  placeholder="Description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 py-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startTime">
                    Start Time
                  </label>
                  <DatePicker
                    selected={formData.startTime}
                    onChange={(date) => dispatch(updateFormData({ ...formData, startTime: date }))}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="startTime"
                  />
                </div>
                <div className="w-full md:w-1/2 px-3 py-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endTime">
                    End Time
                  </label>
                  <DatePicker
                    selected={formData.endTime}
                    onChange={(date) => dispatch(updateFormData({ ...formData, endTime: date }))}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="endTime"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Attendees
                </label>
                {formData.attendees.map((attendee, index) => (
                  <div key={index} className="flex mb-2 items-center">
                    <input
                      type="email"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Attendee Email"
                      value={attendee}
                      onChange={(e) => handleAttendeeChange(index, e)}
                    />
                    <button
                      type="button"
                      className="ml-2 p-2 bg-red-500 text-white rounded-full"
                      onClick={() => removeAttendee(index)}
                    >
                      <FaTrash />
                    </button>
                      <button
                        type="button"
                        className="ml-2 p-2 bg-green-500 text-white rounded-full"
                        onClick={addAttendee}
                      >
                        <FaPlus />
                      </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                onClick={onClose}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
              >
                Schedule Meeting
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ScheduleMeetingForm;
