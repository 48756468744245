// // require('dotenv').config();
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import {BrowserRouter} from 'react-router-dom';
// import App from './App';
// import { UserProvider } from './contextapi/UserEmailContext';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // <React.StrictMode>
//   //   <UserProvider>
//   //     <App />
//   //   </UserProvider>
//   // </React.StrictMode>
//   <UserProvider>
//       <App />
//     </UserProvider>
// );

// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux"; // Import Provider from react-redux
import { store } from "./redux/store"; // Import your Redux store
import WrappedApp from "./App"; // Import your main App component

ReactDOM.render(
	<Provider store={store}>
		<WrappedApp />
	</Provider>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
