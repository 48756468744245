import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	toggleScheduleMeetingModal,
	toggleLiveMeetingModal,
	LiveMeetings,
} from "../redux/slices/sidebarSlice";
import { Link, useNavigate } from "react-router-dom";
import Home from "./Home";

const Sidebar = ({ isScheduleMeetingModalOpen, isLiveMeetingModalOpen, MeetingDetails }) => {
	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.sidebar.isLoading);
	const userEmail = useSelector((state) => state.user.userEmail);
	const fetchedMeetings = useSelector((state) => state.sidebar.Meetings);
	const [page, setPage] = useState(1);
	const [meetings, setMeetings] = useState([]);
	const navigate = useNavigate();
	const [currentTab, setCurrentTab] = useState("Meetings");
	const [totalPage, setTotalPages] = useState(1);

	useEffect(() => {
		console.log("Meetings 123", fetchedMeetings);

		if (fetchedMeetings.totalEvents > 0) {
			setMeetings(fetchedMeetings.alleventslist);
		}
	}, [fetchedMeetings]);

	const handleScheduleMeeting = () => {
		setCurrentTab("Schedule Meeting");
		dispatch(toggleScheduleMeetingModal());
		navigate("/notetaker");
	};

	const handleLiveMeeting = () => {
		setCurrentTab("Add to live meeting");
		dispatch(toggleLiveMeetingModal());
		navigate("/notetaker");
	};

	const handleShowMeetings = () => {
		setCurrentTab("Meetings");
		dispatch(LiveMeetings(userEmail));
		navigate("/notetaker");
	};

	const handleAppandIntegration = () => {
		setCurrentTab("Apps & Integrations");
		navigate("/notetaker/user/integrations");
	};

	const handleCalenderMeeting = () => {
		setCurrentTab("Calendar");
		// dispatch(toggleScheduleMeetingModal());
		navigate("/notetaker");
	};

	return (
		<>
      <div className="fixed top-0 left-0 h-screen w-64 bg-gray-200 text-white p-4">
				<ul>
					<li>
						<button
							className={`w-full font-bold py-2 px-4 rounded mb-4 ${currentTab === "Schedule Meeting" ? "bg-blue-500 text-white" : "bg-gray-500 text-white"}`}
							onClick={handleScheduleMeeting}
							disabled={isLoading}
						>
							{/* {isLoading ? 'Scheduling...' : 'Schedule Meetings'} */}
							Schedule Meetings
						</button>
					</li>
					<li>
						<button
							className={`w-full font-bold py-2 px-4 rounded mb-4 ${currentTab === "Add to live meeting" ? "bg-blue-500 text-white" : "bg-gray-500 text-white"}`}
							onClick={handleLiveMeeting}
							disabled={isLoading}
						>
							{/* {isLoading ? 'Adding...' : 'Add to Live Meeting'} */}
							Add to Live Meeting
						</button>
					</li>
					<li>
						<button
							className={`w-full font-bold py-2 px-4 rounded mb-4 ${currentTab === "Meetings" ? "bg-blue-500 text-white" : "bg-gray-500 text-white"}`}
							onClick={handleShowMeetings}
							disabled={isLoading}
						>
							{/* {isLoading ? 'Loading...' : 'Meetings'} */}
							Meetings
						</button>
					</li>
					<li>
						<button
							className={`w-full font-bold py-2 px-4 rounded ${currentTab === "Apps & Integrations" ? "bg-blue-500 text-white" : "bg-gray-500 text-white"}`}
							onClick={handleAppandIntegration}
							disabled={isLoading}
						>
							Apps & Integrations
						</button>
					</li>
				</ul>
			</div>
		</>
	);
};
export default Sidebar;
