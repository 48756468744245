
import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoUrl }) => {
	return (
		<div className="bg-gray-500 p-2 rounded shadow-md my-16">
			<ReactPlayer url={videoUrl} controls width="100%" height="auto" />
		</div>
	);
};

export default VideoPlayer;
