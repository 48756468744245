import React, { useRef, useState } from "react";
import { TbRewindBackward10, TbRewindForward10 } from "react-icons/tb";
import { IoVolumeMuteOutline } from "react-icons/io5";
import { VscUnmute } from "react-icons/vsc";

const AudioPlayer = ({ audioUrl }) => {
	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	//   const [volume, setVolume] = useState(1);
	const [isMuted, setIsMuted] = useState(false);

	// Format time helper
	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60)
			.toString()
			.padStart(2, "0");
		return `${minutes}:${seconds}`;
	};

	const handlePlayPause = () => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	const handleTimeUpdate = () => {
		setCurrentTime(audioRef.current.currentTime);
	};

	const handleLoadedMetadata = () => {
		setDuration(audioRef.current.duration);
	};

	const handleRewind = () => {
		audioRef.current.currentTime -= 10;
	};

	const handleForward = () => {
		audioRef.current.currentTime += 10;
	};

	// const handleVolumeChange = (e) => {
	// 	const volumeValue = e.target.value;
	// 	audioRef.current.volume = volumeValue;
	// 	setVolume(volumeValue);
	// };

	const handleMuteToggle = () => {
		audioRef.current.muted = !isMuted;
		setIsMuted(!isMuted);
	};

	return (
		<div className="flex items-center space-x-4 bg-gray-500 text-white p-3 rounded-lg">
			{/* Rewind Button */}
			<button onClick={handleRewind} className="text-gray-300 hover:text-white">
				<TbRewindBackward10 />
			</button>

			{/* Play/Pause Button */}
			<button onClick={handlePlayPause} className="text-gray-300 hover:text-white">
				{isPlaying ? "⏸" : "▶️"}
			</button>

			{/* Forward Button */}
			<button onClick={handleForward} className="text-gray-300 hover:text-white">
				<TbRewindForward10 />
			</button>

			{/* Current Time */}
			<span className="text-sm">{formatTime(currentTime)}</span>

			{/* Progress Bar */}
			<input
				type="range"
				className="w-full h-1 bg-gray-700 rounded-lg"
				value={currentTime}
				max={duration}
				onChange={(e) => (audioRef.current.currentTime = e.target.value)}
			/>

			{/* Duration */}
			<span className="text-sm">{formatTime(duration)}</span>

			{/* Volume Control */}
			{/* <input
				type="range"
				className="w-12 h-1 bg-gray-700 rounded-sm"
				value={volume}
				min="0"
				max="1"
				step="0.1"
				onChange={handleVolumeChange}
			/> */}

			<button onClick={handleMuteToggle} className="text-gray-300 hover:text-white">
				{isMuted ? <IoVolumeMuteOutline /> : <VscUnmute />}
			</button>

			{/* Audio Element */}
			<audio
				ref={audioRef}
				src={audioUrl}
				onTimeUpdate={handleTimeUpdate}
				onLoadedMetadata={handleLoadedMetadata}
			/>
		</div>
	);
};

export default AudioPlayer;
