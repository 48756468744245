import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	title1: "",
	meetingUrl: "",
	isLoading: false,
	error: null, // Add an error state to handle errors
};

const liveMeetingSlice = createSlice({
	name: "liveMeeting",
	initialState,
	reducers: {
		setTitle: (state, action) => {
			state.title1 = action.payload;
		},
		setMeetingUrl: (state, action) => {
			state.meetingUrl = action.payload;
		},
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setError: (state, action) => {
			// New action to set errors
			state.error = action.payload;
		},
		resetForm: (state) => {
			state.title1 = "";
			state.meetingUrl = "";
			state.isLoading = false;
			state.error = null; // Reset the error state
		},
	},
});

export const { setTitle, setMeetingUrl, setLoading, resetForm, setError } = liveMeetingSlice.actions;

export const addToLiveMeeting = (meetingData) => async (dispatch) => {
	dispatch(setLoading(true));
	dispatch(setError(null)); // Reset error state before API call
	try {
		console.log(meetingData);
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/api/notetaker/start-live-meeting`,
			meetingData,
		);
		// const response = await axios.post(
		// 	`${process.env.REACT_APP_API_URL}/api/notetaker-bot`,
		// 	meetingData,
		// );
		console.log("response", response);
		console.log("Meeting added successfully");
	} catch (error) {
		console.error("Error adding meeting:", error);
		dispatch(setError(error.response ? error.response.data.message : "Failed to add meeting."));
	}
	dispatch(setLoading(false));
};

export default liveMeetingSlice.reducer;
