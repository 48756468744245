import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FaClock } from "react-icons/fa";
import { format } from "date-fns";

const MeetingTable = ({
	meetings,
	handleMeetingDetails,
	handleMeetingLinkClick,
	currentPage,
	totalPages,
	handlePageChange,
	isLoading1,
	selectedMeetingType,
	setSelectedMeetingType,
}) => {
	return (
		<div className="mt-2">
			<div className="mb-4 flex space-x-4">
				<button
					className={`px-4 py-2 rounded ${selectedMeetingType === "Live" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
					onClick={() => setSelectedMeetingType("Live")}
				>
					Live Meetings
				</button>
				<button
					className={`px-4 py-2 rounded ${selectedMeetingType === "Upcoming" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
					onClick={() => setSelectedMeetingType("Upcoming")}
				>
					Upcoming Meetings
				</button>
				<button
					className={`px-4 py-2 rounded ${selectedMeetingType === "Scheduled" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
					onClick={() => setSelectedMeetingType("Scheduled")}
				>
					Scheduled Meetings
				</button>
				<button
					className={`px-4 py-2 rounded ${selectedMeetingType === "Incomplete" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
					onClick={() => setSelectedMeetingType("Incomplete")}
				>
					Incomplete Meetings
				</button>
			</div>

			{meetings.length === 0 ? (
				<div className="text-center text-gray-500 mt-8 border-dotted border-2 border-gray-400 py-24 rounded-lg">
					No meetings available for the selected category.
				</div>
			) : (
				<div className="overflow-x-auto">
					<table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
						<thead className="bg-gray-300 h-12">
							<tr>
								<th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Meeting Summary
								</th>
								<th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Status
								</th>
								<th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Date & Time
								</th>
								<th className="px-4 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
									Actions
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{meetings.map((event, index) => (
								<tr key={index} className="hover:bg-gray-100">
									<td
										className="px-4 py-2 text-sm font-medium text-gray-700 cursor-pointer"
										onClick={() => handleMeetingDetails(event.MeetingId, event.status)}
									>
										{event.summary}
									</td>
									<td className="px-4 py-2 text-sm text-gray-500">{event.status}</td>
									<td className="px-4 py-2 text-sm text-gray-500">
										<div className="flex items-center">
											<FontAwesomeIcon
												icon={faCalendarAlt}
												className="text-gray-500 mr-2"
											/>
											{format(new Date(event.start), "EEE, MMM do")}
											<FaClock className="inline-block ml-2 mr-1" />
											{format(new Date(event.start), "h:mm a")}
										</div>
									</td>
									<td className="px-4 py-2 text-center">
										<button
											onClick={() => {
												handleMeetingLinkClick(event.url);
												window.open(event.url, "_blank");
											}}
											className="p-1 bg-gray-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="h-5 w-5"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M5 4a2 2 0 00-2 2v8a2 2 0 002 2h5v2H8l3 3-3-3h-2v-2h5a2 2 0 002-2V6a2 2 0 00-2-2H5zm5 10V8l5 3-5 3z"
													clipRule="evenodd"
												/>
											</svg>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}

			{/* Pagination */}
			{meetings.length > 0 && (
				<div className="flex justify-center mt-6">
					{totalPages > 1 && (
						<>
							<button
								onClick={() => handlePageChange("prev")}
								disabled={currentPage === 1 || isLoading1}
								className="mx-2 p-2 bg-gray-200 hover:bg-gray-300 rounded disabled:opacity-50"
							>
								Prev
							</button>
							<span className="p-2 text-gray-600">
								Page {currentPage} of {totalPages}
							</span>
							<button
								onClick={() => handlePageChange("next")}
								disabled={currentPage === totalPages || isLoading1}
								className="mx-2 p-2 bg-gray-200 hover:bg-gray-300 rounded disabled:opacity-50"
							>
								Next
							</button>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default MeetingTable;
