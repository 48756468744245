import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SpeakerModal from "../../speakereditmodal";
import { MdSpeakerNotesOff } from "react-icons/md";

// const MeetingNotes = () => {
const MeetingNotes = ({ meetTranscription }) => {
	const location = useLocation();
	const [transcriptionType, setTranscriptionType] = useState("assemblyspeaker");
	const [UpdatedMappedTranscript, setUpdatedMappedTranscript] = useState([]);
	const [isTranscriptsUpdated, setIsTranscriptsUpdated] = useState(false);
	const [meetingDetails, setMeetingDetails] = useState(meetTranscription);
	const [highlightedTranscript, setHighlightedTranscript] = useState(null);
	const transcriptRefs = useRef([]);
	const audioPlayerRef = useRef(null);
	const [isloading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedSpeaker, setSelectedSpeaker] = useState("");
	const [selectedSpeakerTimestamp, setSelectedSpeakerTimestamp] = useState("");
	const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
	console.log("Meeting Notes", meetTranscription);

	useEffect(() => {
		const sendMappedTranscripts = async () => {
			if (!meetingDetails?.meeting?.meetingId) return;
			try {
				setIsLoading(true);
				console.log("UpdatedMappedTranscription", UpdatedMappedTranscript);
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/api/notetaker/update-mapped-transcript`,
					{
						meetingId: meetingDetails.meeting.meetingId,
						UpdatedMappedTranscript,
					},
				);

				setMeetingDetails((prevDetails) => ({
					...prevDetails,
					meeting: {
						...prevDetails.meeting,
						MappedTranscript: response.data.MappedTranscript,
					},
				}));
				console.log("Updated meeting:", response.data);
			} catch (error) {
				console.error("Error updating mapped transcript:", error);
			} finally {
				setIsTranscriptsUpdated(false);
				setIsLoading(false);
			}
		};

		if (isTranscriptsUpdated && meetingDetails?.meeting?.meetingId) {
			sendMappedTranscripts();
		}
	}, [isTranscriptsUpdated, UpdatedMappedTranscript, meetingDetails?.meeting?.meetingId]);

	const handleTranscriptionTypeChange = (type) => {
		setTranscriptionType(type);
	};
	const formatTime = (milliseconds) => {
		let totalSeconds = Math.floor(milliseconds / 1000);
		let hours = Math.floor(totalSeconds / 3600);
		let minutes = Math.floor((totalSeconds % 3600) / 60);
		let seconds = totalSeconds % 60;
		return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
	};
	const handleOpenModal = (event, speaker, speakerslectedtime) => {
		const rect = event.target.getBoundingClientRect();
		setModalPosition({
			top: rect.bottom + window.scrollY + 40,
			left: rect.left + window.scrollX + 110,
		});
		setSelectedSpeaker(speaker);
		setSelectedSpeakerTimestamp(speakerslectedtime);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setSelectedSpeaker("");
	};
	// const handleApplyToCurrentSpeaker = async (speakerName) => {
	// 	setIsLoading(true);
	// 	const updatedTranscripts = meetingDetails.meeting.MappedTranscript.map((entry) => {
	// 		if (entry.start === selectedSpeakerTimestamp) {
	// 			return { ...entry, speakerName: speakerName };
	// 		}
	// 		return entry;
	// 	});

	// 	setUpdatedMappedTranscript(updatedTranscripts);
	// 	setIsTranscriptsUpdated(true);
	// 	setIsModalOpen(false);
	// 	setIsLoading(false);
	// };

	const handleApplyToCurrentSpeaker = async (speakerName) => {
		try {
			setIsLoading(true); // Show loader
			const updatedTranscripts = meetingDetails.meeting.MappedTranscript.map((entry) => {
				if (entry.start === selectedSpeakerTimestamp) {
					return { ...entry, speakerName: speakerName };
				}
				return entry;
			});

			setUpdatedMappedTranscript(updatedTranscripts);
			setIsTranscriptsUpdated(true);
			setIsModalOpen(false);
		} catch (error) {
			console.error("Error updating the current speaker's name:", error);
		} finally {
			setIsLoading(false); // Hide loader
		}
	};

	const handleApplyToAllSpeaker = async (updatedName) => {
		try {
			setIsLoading(true); // Show loader
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/api/notetaker/update-all-participant-names`,
				{
					meetingId: meetingDetails.meeting.meetingId,
					currentName: selectedSpeaker,
					newName: updatedName,
				},
			);

			// Update the state with the new MappedTranscript from the response
			setMeetingDetails((prevDetails) => ({
				...prevDetails,
				meeting: {
					...prevDetails.meeting,
					MappedTranscript: response.data.MappedTranscript,
				},
			}));
			console.log("Updated all instances of participant names:", response.data);
		} catch (error) {
			console.error("Error updating all participant names:", error);
		} finally {
			handleCloseModal();
			setIsLoading(false); // Hide loader
		}
	};
	const handleTranscriptClick = (timestamp) => {
		console.log("Clicked transcript timestamp:", timestamp);
		const timeParts = timestamp.split(":").map((part) => parseInt(part, 10));
		const timeInSeconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2];

		if (audioPlayerRef.current && audioPlayerRef.current.audio) {
			const audioElement = audioPlayerRef.current.audio;
			if (audioElement) {
				console.log("Setting audio time to:", timeInSeconds);
				audioElement.currentTime = timeInSeconds;
				audioElement.play();
				setHighlightedTranscript(timestamp);
			} else {
				console.log("Audio element not found.");
			}
		} else {
			console.log("Audio player ref or audio not available.");
		}
	};
	const getTranscription = (type) => {
		if (type === "assemblyspeaker") {
			return renderAssemblySpeakerTranscription();
		} else if (type === "assemblytext") {
			return renderAssemblySpeakerTextTranscription();
		}
		return "";
	};
	const TranscriptionDisplay = ({ type }) => {
		return (
			<div className="bg-gray-200 p-4 overflow-y-auto rounded-b-lg" style={{ height: "32rem" }}>
				{getTranscription(type)}
			</div>
		);
	};
	const renderAssemblySpeakerTextTranscription = () => {
		const { assemblytranscripts } = meetingDetails.meeting;
		const combinedText = assemblytranscripts.plainTextTranscription;
		console.log("Combined Text", combinedText);
		if (combinedText) {
			return <div>{combinedText}</div>;
		} else {
			return (
				<div className="flex flex-col items-center justify-center h-96 text-center font-semibold">
					<p className="mb-4">{assemblytranscripts.message}</p>
					<MdSpeakerNotesOff size={48} />
				</div>
			);
		}
	};
	const renderAssemblySpeakerTranscription = () => {
		if (!meetingDetails.meeting.MappedTranscript) return null;

		return (
			<div className="transcript-container">
				{meetingDetails.meeting.MappedTranscript.length > 0 ? (
					meetingDetails.meeting.MappedTranscript.map((entry, index) => (
						<div
							key={index}
							ref={(el) => (transcriptRefs.current[entry.start] = el)}
							className={`cursor-pointer p-1 rounded-md ${
								highlightedTranscript === entry.start ? "bg-yellow-200" : "hover:bg-gray-100"
							}`}
							onClick={() => handleTranscriptClick(formatTime(entry.start))}
						>
							<div className="flex items-center justify-between">
								<div
									className="flex items-center space-x-1"
									onClick={(event) => {
										event.stopPropagation();
										handleOpenModal(event, entry.speakerName, entry.start);
									}}
								>
									<strong className="text-slate-700">{entry.speakerName}</strong>
									<button className="text-slate-400 text-xl pb-2">{"\u2304"}</button>
								</div>
								<span className="text-sm text-gray-500">{formatTime(entry.start)}</span>
							</div>
							<p className="mt-1 text-gray-800">{entry.text}</p>
						</div>
					))
				) : (
					<div className="flex flex-col items-center justify-center h-96 text-center font-semibold">
						<p className="mb-4">{meetingDetails.meeting.assemblytranscripts.message}</p>
						<MdSpeakerNotesOff size={48} />
					</div>
				)}
			</div>
		);
	};
	return (
		<>
			<div className="my-4">
				<div className="border-b-4 rounded-t-lg">
					<button
						className={`font-bold py-2 px-4 mr-2 rounded  ${
							transcriptionType === "assemblyspeaker"
								? "text-blue-700 border-b-4 border-blue-700"
								: "text-white-200"
						}`}
						onClick={() => handleTranscriptionTypeChange("assemblyspeaker")}
					>
						Transcript
					</button>
					<button
						className={`font-bold py-2 px-4 mr-2 rounded ${
							transcriptionType === "assemblytext"
								? "text-blue-700 border-b-4 border-blue-700"
								: "text-white-200"
						}`}
						onClick={() => handleTranscriptionTypeChange("assemblytext")}
					>
						Notes
					</button>
				</div>
				{isloading ? (
					<div className="flex justify-center items-center h-96">
						<div className="w-6 h-6 border-4 border-blue-400 border-t-blue-600 rounded-full animate-spin"></div>
						<p className="ml-4 text-blue-600 font-medium">Updating...</p>
					</div>
				) : (
					<>
						<TranscriptionDisplay type={transcriptionType} />
						<SpeakerModal
							isOpen={isModalOpen}
							onRequestClose={handleCloseModal}
							speakerName={selectedSpeaker}
							orderedSpeaker={meetingDetails.meeting.orderedSpeaker}
							position={modalPosition}
							onApplyToCurrentSpeaker={handleApplyToCurrentSpeaker}
							onApplyToAllSpeaker={handleApplyToAllSpeaker}
							timestamp={selectedSpeakerTimestamp}
						/>
					</>
				)}
			</div>
		</>
	);
};

export default MeetingNotes;
