import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoMdCheckmarkCircle } from "react-icons/io";

const IntegrationCard = ({ icon, title, description, isActive, onToggle, status, onClick }) => (
	<div
		className="flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow-md mt-6"
		onClick={onClick}
	>
		<div className="flex space-x-4">
			{icon}
			<div>
				<h3 className="text-lg font-medium">{title}</h3>
				<p className="text-xs bg-gray-200 inline-block rounded px-1">Calendar</p>
				<p className="text-xs text-gray-500">{description}</p>
			</div>
			{status === "Active" ? (
				<div className="flex">
					<IoMdCheckmarkCircle
						size={12}
						color="#45ac1b"
						style={{ marginTop: "2px", paddingRight: "1px" }}
					/>
					<p className="text-xs">{status}</p>
				</div>
			) : (
				<p className="mt-1 text-gray-400" style={{ fontSize: "10px", whiteSpace: "nowrap" }}>
					{status}
				</p>
			)}
		</div>
		{/* <button
        onClick={onToggle}
        className={`${
          isActive ? 'bg-green-500' : 'bg-gray-300'
        } relative inline-flex h-6 w-12 items-center rounded-full transition-colors duration-300`}
      >
        <span
          className={`${
            isActive ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-300`}
        />
      </button> */}
	</div>
);

export default IntegrationCard;
