// redux/slices/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

console.log(JSON.parse(sessionStorage.getItem("user")));

const getUserinfo = (fieldName) => {
	try {
		let user = JSON.parse(sessionStorage.getItem("user"));
		return user[fieldName];
	} catch {
		return "";
	}
};

const initialState = {
	userEmail: getUserinfo("email"),
	userPicture: getUserinfo("picture"),
	userName: getUserinfo("name"),
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserEmail: (state, action) => {
			state.userEmail = action.payload;
		},
		setUserPicture: (state, action) => {
			state.userPicture = action.payload;
		},
		setUserName: (state, action) => {
			state.userName = action.payload;
		},
		Logout: (state) => {
			sessionStorage.removeItem("user");
			state.userEmail = "";
			state.userPicture = "";
			state.userName = "";
		},
	},
});

export const { setUserEmail, setUserPicture, setUserName, Logout } = userSlice.actions;

export default userSlice.reducer;
