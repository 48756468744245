// import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useUser } from '../contextapi/UserEmailContext';
// import { useNavigate } from 'react-router-dom';

// const UserDummyComponent = () => {
//   const location = useLocation(); // Get the current location
//   const navigate = useNavigate();
//   const { setUserEmail,setUserPicture,setUserName } = useUser();

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const email = queryParams.get('email');
//     const picture = queryParams.get('picture')
//     const name = queryParams.get('name')
//     console.log(email);
//     console.log(picture);
//     console.log(name);
//     const user = { email, picture, name };
//     sessionStorage.setItem('user', JSON.stringify(user));
//     // setUserEmail(email || '');
//     setUserPicture(picture || '')
//     setUserName(name || '')
//     navigate('/notetaker');
//   }, [location, navigate, setUserEmail]);

//   return <div>Loading...</div>;
// };

// export default UserDummyComponent;

// UserDummyComponent.js
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserEmail, setUserPicture, setUserName } from "../redux/slices/userSlice";

const UserDummyComponent = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const email = queryParams.get("email");
		const picture = queryParams.get("picture");
		const name = queryParams.get("name");

		const user = { email, picture, name };
		sessionStorage.setItem("user", JSON.stringify(user));

		// Update Redux store instead of using Context API
		console.log(user);
		dispatch(setUserEmail(email || ""));
		dispatch(setUserPicture(picture || ""));
		dispatch(setUserName(name || ""));

		navigate("/notetaker");
	}, [location, navigate, dispatch]);

	return <div>Loading...</div>;
};

export default UserDummyComponent;
