import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import IntegrationCard from "./IntegrationsCard";
import { SiGooglecalendar } from "react-icons/si";
import { PiMicrosoftOutlookLogoFill } from "react-icons/pi";
import { IoVideocam } from "react-icons/io5";
import IntegrationsDetails from "./IntegrationsDetails";
import { icon } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";

const Integrations = () => {
	const navigate = useNavigate();
	const [googleCalendarActive, setGoogleCalendarActive] = useState(false);
	const [outlookConnected, setOutlookConnected] = useState(false);
	const [zoomConnected, setZoomConnected] = useState(false);
	const [GoogleEmail, setGoogleEmail] = useState("");
	const [OutlookEmail, setOutlookEmail] = useState("");
	const [ZoomEmail, setZoomEmail] = useState("");
	const toggleSwitch = (isActive) => (isActive ? "bg-green-500" : "bg-gray-300");
	const [selectedIntegration, setSelectedIntegration] = useState(null);

	const fetchCalendar = async () => {
		const user = JSON.parse(sessionStorage.getItem("user"));
		const userEmail = user?.email;
		// setUserEmail(userEmail);
		const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/notetaker/fetchcalendar`, {
			userEmail,
		});
		console.log("Calendar", response.data);
		setGoogleCalendarActive(response.data.calendarStatus.google);
		setOutlookConnected(response.data.calendarStatus.msTeams);
		setZoomConnected(response.data.calendarStatus.zoom);
		if (response.data.calendarStatus.google) {
			setSelectedIntegration("Google Calendar");
		} else if (response.data.calendarStatus.msTeams) {
			setSelectedIntegration("Microsoft Outlook");
		} else if (response.data.calendarStatus.zoom) {
			setSelectedIntegration("Zoom Calendar");
		}
		setGoogleEmail(response.data.platformEmailMap.google);
		setOutlookEmail(response.data.platformEmailMap.microsoft);
		setZoomEmail(response.data.platformEmailMap.zoom);
	};
	const checkActiveCalendar = () => {
		if (googleCalendarActive) {
			setSelectedIntegration("Google Calendar");
		} else if (outlookConnected) {
			setSelectedIntegration("Microsoft Outlook");
		} else if (zoomConnected) {
			setSelectedIntegration("Zoom Calendar");
		}
	};
	useEffect(() => {
		fetchCalendar();
	}, []);
	// useEffect(()=>{
	//   console.log("CheckingActiveCalendar");
	//   checkActiveCalendar();
	// },[])

	const user = JSON.parse(sessionStorage.getItem("user"));
	const HandleGoogleIntegrations = () => {
		const email = user?.email;
		if (email) {
			window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google?email=${encodeURIComponent(email)}&mode=integration`;
			console.log("Redirecting to Microsoft OAuth with email:", email);
		} else {
			console.log("No email found in sessionStorage.");
		}
	};
	const HandleTeamsIntegrations = () => {
		const email = user?.email;
		if (email) {
			window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/microsoft?email=${encodeURIComponent(email)}&mode=integration`;
			console.log("Redirecting to Microsoft OAuth with email:", email);
		} else {
			console.log("No email found in sessionStorage.");
		}
	};
	const HandleZoomIntegrations = () => {
		const email = user?.email;
		if (email) {
			window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/zoom?email=${encodeURIComponent(email)}&mode=integration`;
			console.log("Redirecting to Microsoft OAuth with email:", email);
		} else {
			console.log("No email found in sessionStorage.");
		}
	};

	const integrationDetails = {
		"Google Calendar": {
			icon: <SiGooglecalendar size={52} color="#0691e1" />,
			title: "Google Calendar",
			description:
				"Allow your NoteTaker Assistant to join and generate meeting reports for Google Calendar events.",
			status: googleCalendarActive ? "Active" : "Not Connected",
			email: GoogleEmail,
			details:
				"You have successfully connected NoteTaker to Google Calendar. NoteTaker can access and edit events on your calendar.",
			connectButton: HandleGoogleIntegrations,
			details1: "Google Calendar isn't connected yet",
		},
		"Microsoft Outlook": {
			icon: <PiMicrosoftOutlookLogoFill size={52} color="#0691e1" />,
			title: "Microsoft Outlook",
			description:
				"Allow your NoteTaker Assistant to join and generate meeting reports for Microsoft Outlook events.",
			status: outlookConnected ? "Active" : "Not Connected",
			email: OutlookEmail,
			details:
				"You have successfully connected NoteTaker to Google Calendar. NoteTaker can access and edit events on your calendar.",
			connectButton: HandleTeamsIntegrations,
			details1: "Microsoft Outlook isn't connected yet",
		},
		"Zoom Calendar": {
			icon: <IoVideocam size={52} color="#0691e1" />,
			title: "Zoom Calendar",
			description:
				"Allow your NoteTaker Assistant to join and generate meeting reports for Zoom Calendar events.",
			status: zoomConnected ? "Active" : "Not Connected",
			email: ZoomEmail,
			details:
				"You have successfully connected NoteTaker to Google Calendar. NoteTaker can access and edit events on your calendar.",
			connectButton: HandleZoomIntegrations,
			details1: "Zoom Calendar isn't connected yet",
		},
	};

	return (
		<div>
			<div className="flex items-center mb-2">
				{/* <Navbar /> */}
				<button className="flex items-center px-2 py-2 rounded-md" onClick={() => navigate(-1)}>
					<AiOutlineArrowLeft size={18} color="#444444" />
					<p className="px-1 text-gray-500">|</p>
				</button>
				<h2>Apps & Integrations</h2>
			</div>
			{/* <div className="w-full h-1 bg-gray-400 px-4"></div>  */}
			{/* <hr className="border-2 border-spacing-x-4 border-gray-400 my-1" /> */}
			<hr style={{ border: "1px solid #aaaaaa" }} />
			{/* <hr  /> */}
			<div className="flex">
				<div className="w-1/3">
					<p className="text-wrap p-2 text-xs text-gray-500">
						Your integrations can only be configured and used by you. For example, connecting
						Gmail will only give you access to your inbox topic summaries.
					</p>
					<h2 className="text-lg font-serif px-4 text-gray-700 bg-gray-300 rounded py-2">
						Calendar
					</h2>
					<div className="space-y-6">
						<IntegrationCard
							icon={<SiGooglecalendar size={52} color="#0691e1" />}
							title="Google Calendar"
							description="Allow your assistant to join and generate meeting reports for Google Calendar events."
							isActive={googleCalendarActive}
							status={googleCalendarActive ? "Active" : "Not Connected"}
							onClick={() => setSelectedIntegration("Google Calendar")}
						/>
						<IntegrationCard
							icon={<PiMicrosoftOutlookLogoFill size={52} color="#0691e1" />}
							title="Microsoft Outlook"
							description="Allow your NoteTaker Assistant to join and generate meeting reports for Microsoft Outlook events."
							isActive={outlookConnected}
							status={outlookConnected ? "Active" : "Not Connected"}
							onClick={() => setSelectedIntegration("Microsoft Outlook")}
						/>
						<IntegrationCard
							icon={<IoVideocam size={52} color="#0691e1" />}
							title="Zoom Calendar"
							description="Allow your NoteTaker Assistant to join and generate meeting reports for Zoom Calendar events."
							isActive={zoomConnected}
							onToggle={() => setGoogleCalendarActive(!googleCalendarActive)}
							status={zoomConnected ? "Active" : "Not Connected"}
							onClick={() => setSelectedIntegration("Zoom Calendar")}
						/>
					</div>
				</div>
				<div className="border-l border-gray-400 h-screen"></div>
				<div className="w-2/3">
					{selectedIntegration ? (
						// <IntegrationsDetails icon={<SiGooglecalendar size={52} color="#0691e1"/>} />
						<div>
							<div className="flex p-4">
								{integrationDetails[selectedIntegration].icon}
								<div>
									<div className="flex">
										<h2 className="text-xl font-semibold px-3">
											{integrationDetails[selectedIntegration].title}
										</h2>
										<p className="text-xs py-2 px-2">
											{integrationDetails[selectedIntegration].status}
										</p>
									</div>
									<p className="text-xs bg-gray-200 inline-block rounded px-2 mx-3">
										Calendar
									</p>
								</div>
							</div>
							<p className="text-gray-500 px-4 text-sm">
								{integrationDetails[selectedIntegration].description}
							</p>
							{integrationDetails[selectedIntegration].status === "Active" ? (
								<div>
									<div className="flex justify-between items-center my-4">
										<p className="bg-gray-200 px-4 py-2 text-blue-600 rounded-lg font-serif ml-4">
											Default Calendar
										</p>
										<button className="text-red-500 border-2 px-4 py-2 rounded-lg font-mono mr-6">
											Disconnect
										</button>
									</div>
									<hr style={{ border: "1px solid #aaaaaa" }} />
									<div className="bg-gray-200 p-6">
										<p className="font-semibold font-sans text-gray-800 py-2">
											{integrationDetails[selectedIntegration].email} is connected
										</p>
										<p className="text-sm text-gray-600 pb-2">
											{integrationDetails[selectedIntegration].details}
										</p>
										<p className="font-normal py-2 text-sm">Your settings</p>
										<ul className="list-disc list-inside mb-4">
											<li className="ml-6 text-xs text-gray-600 py-1">
												NoteTaker Assistant will auto-join all meetings (Manage)
											</li>
											<li className="ml-6 text-xs text-gray-600 py-1">
												NoteTaker will not update calendar events with a meeting
												summary (Manage)
											</li>
											<li className="ml-6 text-xs text-gray-600 py-1">
												NoteTaker Assistant will auto-join all meetings (Manage)
											</li>
										</ul>
										<p className="mt-8 text-sm font-serif">Your synced calendars</p>
										<div className="flex items-center ml-2 pt-4 pb-32">
											<button
												// onClick={onToggle}
												className={`${
													true ? "bg-green-500" : "bg-gray-300"
												} relative inline-flex h-6 w-12 items-center rounded-full transition-colors duration-300`}
											>
												<span
													className={`${
														true ? "translate-x-6" : "translate-x-1"
													} inline-block h-4 w-4 transform bg-white rounded-full transition-transform duration-300`}
												/>
											</button>
											<p className="text-xs ml-3">
												{integrationDetails[selectedIntegration].email}
											</p>
										</div>
									</div>
								</div>
							) : (
								<div>
									<button
										className="text-white bg-purple-700 border-2 px-4 py-2 my-5 rounded-lg font-mono ml-3 mr-6"
										onClick={integrationDetails[selectedIntegration].connectButton}
									>
										Connect
									</button>
									<p className="text-xs text-gray-500 ml-3 pb-5">
										By connecting this service, I agree to NoteTaker's Terms of Service
										and acknowledge I have read the Privacy Policy.
									</p>
									<hr style={{ border: "1px solid #aaaaaa" }} />
									<div className="m-6 border-dashed border-2 border-gray-300 h-72 flex justify-center items-center">
										<div className="text-center">
											<p className="text-sm font-sans font-semibold">
												{integrationDetails[selectedIntegration].details1}
											</p>
											<p className="text-sm font-sans text-gray-500 py-2">
												Click "Connect" to set it up and get started!
											</p>
											<button
												className="text-white bg-purple-700 border-2 px-4 py-2 my-3 rounded-lg font-mono"
												onClick={
													integrationDetails[selectedIntegration].connectButton
												}
											>
												Connect
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					) : (
						<p>Please select an integration to view details.</p>
					)}
				</div>
			</div>

			{/* <div>
          <button
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded mb-4"
            onClick={HandleTeamsIntegrations}
          >
            Teams Connect
          </button>
          <button
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mt-4 rounded mb-4"
            onClick={HandleZoomIntegrations}
          >
            Zoom Connect
          </button>
        </div> */}
		</div>
	);
};

export default Integrations;
